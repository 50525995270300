<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>domain</md-icon>
                </div>
                <h4 class="title">Company Info</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>Company Name</label>
                                <md-input v-model="company.name"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>Company logo - 파일 선택(png)</label>
                                <md-file v-model="company.logoUrl" @change="onChangeFile($event, 'logo')" accept="*" />
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>Homepage</label>
                                <md-input v-model="company.homepage"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>Email</label>
                                <md-input v-model="company.email"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>Contact</label>
                                <md-input v-model="company.phone"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>Address</label>
                                <md-input v-model="company.address"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Description(KO)</label>
                                <md-textarea v-model="company.description"></md-textarea>
                                <md-icon>description</md-icon>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Description(EN)</label>
                                <md-textarea v-model="subLang.en.description"></md-textarea>
                                <md-icon>description</md-icon>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>회사 소개서 - 파일 선택(pdf)</label>
                                <md-file v-model="company.profileUrl" @change="onChangeFile($event, 'profile')" accept="*" />
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>회사 영상 - 파일 선택(50MB 이하)</label>
                                <md-file v-model="company.videoUrl" @change="onChangeFile($event, 'video')" accept="*" />
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100 save-btn-box">
                            <md-button @click="saveCompanyInfo" class="md-dense md-raised md-success">저장</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <!-- <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>info</md-icon>
                </div>
                <h4 class="title">Company Info</h4>
                </md-card-header>
                <md-card-content>
                <md-table v-model="contents" table-header-color="green">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Num">{{ Number(item.number) === 0 ? "4·3 소개서" : "Content" }}</md-table-cell>
                        <md-table-cell md-label="Thumbnail">
                            <img class="item-thumb" :src="item.thumbnailUrl" alt="..." />
                        </md-table-cell>
                        <md-table-cell md-label="Data Type">{{ item.dataType }}</md-table-cell>
                        <md-table-cell md-label="Title">{{ item.dataTitle }}</md-table-cell>
                        <md-table-cell md-label="Language">{{
                            item.subLang ? "ko,"+Object.keys(item.subLang).toString() : 'ko'
                        }}</md-table-cell>
                        <md-table-cell md-label="Edit">
                            <a href="javascript:void(0);" @click="goToEdit(item.sceneId, item.number)" class="editBtn">edit</a>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
                </md-card-content>
            </md-card>
        </div> -->
    </div>
</template>

<script>
import axios from "axios";
import api from "@/commons/apiUrl";
// import EditDocent from "@/components/Docent/EditDocent.vue";
import imageCompression from 'browser-image-compression';

export default {
    components: {
        // EditDocent
    },
    data() {
        return {
            company: {},
            logo: null,
            profile: null,
            video: null,
            subLang: {
                en: {
                    description: ""
                },
                cn: {
                    description: ""
                },
                jp: {
                    description: ""
                }
            }
        }
    },
    async created() {
        const res = await axios.get(api.GET_DATA(this.$route.params.exhibitionId));
        if(!res.data.success) {
            return;
        }

        console.log(res.data.data);
        this.company = res.data.data.companyInfo;

        // subLang process
        if(res.data.data.metaData) {
            const checkList = ["en", "cn", "jp"];
            checkList.forEach((prop) => {
                if(Object.hasOwnProperty.bind(res.data.data.metaData)(prop)) {
                    this.subLang[prop] = res.data.data.metaData[prop];
                }
            });
        }
    },
    methods: {
        async onChangeFile(e, type) {
            let file = e.target.files[0];
            if(file.type === "image/jpeg" || file.type === "image/png") {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                }
                file = await imageCompression(file, options);
            }

            if(type === "logo") {
                this.logo = file;
            }
            else if(type === "profile") {
                this.profile = file;
            }
            else {
                this.video = file;
            }
        },
        async saveCompanyInfo() {
            const data = new FormData();

            this.logo ? data.append("logo", this.logo) : null;
            this.profile ? data.append("profile", this.profile) : null;
            this.video ? data.append("video", this.video) : null;

            data.append("name", this.company.name);
            data.append("address", this.company.address);
            data.append("description", this.company.description);
            data.append("email", this.company.email);
            data.append("homepage", this.company.homepage);
            data.append("phone", this.company.phone);

            const res = await axios.put(api.UPDATE_COMPANY_INFO(this.$route.params.exhibitionId),
                data,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            const subLangRes = await axios.put(api.UPDATE_COMPANY_INFO_SUBLANG(this.$route.params.exhibitionId),
                this.subLang,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "저장 실패");

                return;
            }

            if(!subLangRes.data.success) {
                this.$store.dispatch("alerts/error", "언어 저장 실패");

                return;
            }

            this.$store.dispatch("alerts/success", "저장 성공");
        }
    }
}
</script>

<style scoped>
.item-thumb {
    max-width: 50px;
}
.save-btn-box {
    text-align: end;
}
.md-button.md-success, .md-button.md-success:hover, .md-button.md-success:focus, .md-button.md-success:active, .md-button.md-success.active, .md-button.md-success:active:focus, .md-button.md-success:active:hover, .md-button.md-success.active:focus, .md-button.md-success.active:hover, .open > .md-button.md-success.dropdown-toggle, .open > .md-button.md-success.dropdown-toggle:focus, .open > .md-button.md-success.dropdown-toggle:hover {
    background-color: #38ac90 !important;
}
</style>